<template>
  <div>
    <div class="common-header">同步Icon</div>
    <el-form label-width="80px" ref="form" :model="formData" :rules="rules" @submit.native.prevent>
      <el-form-item label="同步地址:" prop="url">
        <el-input v-model="formData.url" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save" :loading="loading">同步</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Api from '@/api/devops/icon'
export default {
  props: {
    operType: { type: String, default: 'add' },
  },
  data() {
    return {
      formData: {},
      rules: {
        url: [{ required: true, message: '请输同步地址', trigger: ['blur', 'change'] }],
      },
      loading: false,
    }
  },
  watch: {
    detail() {
      this.formData = {}
      this.$refs['form'].resetFields()
    },
  },

  methods: {
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            let { engineVersionId } = this.$route.query
            let param = {
              url: this.formData.url,
              engineVersionId: engineVersionId,
            }
            await Api.syncIcon(param)

            this.$msg.success('同步成功！')
            this.$emit('save')
          } finally {
            this.loading = false
          }
        }
      })
    },
  },
}
</script>
<style lang="scss"></style>
