<!-- icon -->
<template>
  <div class="h100">
    <div class="h100 flex page-wrapper">
      <common-layout class="flex-1 flex-column h100 w-0">
        <template #header>
          <el-form inline @submit.native.prevent>
            <el-form-item>
              <el-input placeholder="请输入icon名称" v-model="searchForm.name" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="query(1)">查询</el-button>
              <el-button type="primary" @click="handleEvent('async')">同步</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #default>
          <el-table ref="table" :data="tableData" border highlight-current-row height="100%" v-loading="loading">
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column v-for="item in tableHeader" :key="item.code" :prop="item.code" :label="item.name" align="center">
              <template slot-scope="scope">
                <template v-if="item.code === 'fontClass'">
                  <i :class="'p-icon-' + scope.row['fontClass']" class="p_font_family"></i>
                </template>
                <template v-else>{{ scope.row[item.code] }}</template>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template #footer>
          <el-pagination :page-size="page.pageSize" :current-page="page.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
        </template>
      </common-layout>
      <w-form-box @save="save" style="width: 300px" class="ml10 pl10 border-left-line h100 overflow-auto" />
    </div>
  </div>
</template>

<script>
import WFormBox from './formBox.vue'

import Api from '@/api/devops/icon'

export default {
  components: { WFormBox },
  data() {
    return {
      loading: false,
      searchForm: {},
      tableData: [],
      tableHeader: [
        { code: 'name', name: '引擎名称' },
        { code: 'fontClass', name: '图标' },
        { code: 'updatedBy', name: '同步账号' },
        { code: 'updatedTime', name: '同步时间' },
      ],
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
    }
  },
  mounted() {
    this.query()
    this.loadStyle()
  },
  methods: {
    async query(pageNum) {
      try {
        this.loading = true
        pageNum && this.$set(this.page, 'pageNum', pageNum)
        const param = {
          engineVersionId: this.$route.query.engineVersionId,
          ...this.searchForm,
          ...this.page,
        }
        const { data } = await Api.pageIconData(param)
        this.tableData = data.dataList || []
        this.total = data.totalCount
      } finally {
        this.loading = false
      }
    },
    async loadStyle() {
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'

      link.href = await this.getCss()
      const head = document.getElementsByTagName('head')[0]
      head.appendChild(link)
      this.$forceUpdate()
    },
    async getCss() {
      let {data} = await Api.getIconClass(this.$route.query.engineVersionId)
      return data.css
    },
    handleEvent(type) {
      switch (type) {
        case 'async':
          break
      }
    },
    save() {
      this.query(1)
    },
    handleSizeChange(val) {
      this.$set(this.page, 'pageSize', val)
      this.$set(this.page, 'pageNum', 1)
      this.query(1)
    },
    handleCurrentChange(val) {
      this.$set(this.page, 'pageNum', val)
      this.query(val)
    },
  },
}
</script>
<style lang="scss" scoped></style>
